/**
 * @file Information de l'utilisateur
 * @author Craspouille
 */
import { useContext } from "react";
import { ModifyContext } from "../../../pages/LetterPage";
import { getOkayClass } from "../../../utils";

/* Context */
export function UserDetails() {
  const { userDetails, handleClickPanelVisibility } = useContext(ModifyContext);

  return (
    <div
      className="user"
      onClick={handleClickPanelVisibility}
      data-steppers={1}
    >
      <p data-step={0}>
        <span
          className={
            userDetails["gender"] === null || userDetails["gender"] === "non"
              ? "hidden"
              : "okay"
          }
        >
          {userDetails["gender"] != null
            ? userDetails["gender"] === "male"
              ? "M."
              : "Mme"
            : ""}
        </span>
        <span className={getOkayClass(userDetails["firstName"])}>
          {userDetails["firstName"] === null || userDetails["firstName"] === ""
            ? "Prénom"
            : userDetails["firstName"]}
        </span>
        <span className={getOkayClass(userDetails["lastName"])}>
          {userDetails["lastName"] === null || userDetails["lastName"] === ""
            ? "Nom"
            : userDetails["lastName"]}
        </span>
      </p>
      <p data-step={1}>
        <span className={getOkayClass(userDetails["address"])}>
          {userDetails["address"] === null || userDetails["address"] === ""
            ? "Adresse"
            : userDetails["address"]}
        </span>
      </p>
      <p data-step={1}>
        <span className={getOkayClass(userDetails["zipcode"])}>
          {userDetails["zipcode"] === null || userDetails["zipcode"] === ""
            ? "Code postal"
            : userDetails["zipcode"] + ", "}
        </span>
        <span className={getOkayClass(userDetails["city"])}>
          {userDetails["city"] === null || userDetails["city"] === ""
            ? "Ville"
            : userDetails["city"]}
        </span>
      </p>
      <p data-step={2}>
        <span className={getOkayClass(userDetails["email"])}>
          {userDetails["email"] === null || userDetails["email"] === ""
            ? "Adresse email"
            : userDetails["email"]}
        </span>
      </p>
      <p data-step={2}>
        <span className={getOkayClass(userDetails["phone"])}>
          {userDetails["phone"] === null || userDetails["phone"] === ""
            ? "Téléphone"
            : userDetails["phone"].split(/\s*([0-9]{0,2})\s*/).join(" ")}
        </span>
      </p>
    </div>
  );
}
