/**
 * @file Questionnaire identité de l'utilisateur
 * @author Craspouille
 */

export function UserFormIdentity({ cssClass = "usr-global-identity", state }) {
  return (
    <div className={cssClass}>
      <div className="usr-gender">
        <p className="app-font-size-title2">Vous êtes&nbsp;:</p>
        <div>
          <label htmlFor="female">
            <input type="radio" id="female" name={"gender"} value={"female"} />
            <span>Une femme</span>
          </label>
          <label htmlFor="male">
            <input type="radio" id="male" name={"gender"} value={"male"} />
            <span>Un homme</span>
          </label>
          <label htmlFor="autre">
            <input
              type="radio"
              id="autre"
              name={"gender"}
              value={"non"}
              defaultChecked
            />
            <span>Ne souhaite pas le faire apparaître</span>
          </label>
        </div>
      </div>
      <div className="usr-identity">
        <p className="app-font-size-title2">Votre prénom&nbsp;:</p>
        <label htmlFor="firstName">
          <input
            autoFocus
            type="text"
            name="firstName"
            placeholder={"Prénom*"}
            title={"Prénom*"}
            pattern={"[a-zA-ZÀ-ž]"}
            required
          />
        </label>
        <p className="app-font-size-title2">Votre nom&nbsp;:</p>
        <label htmlFor="lastName">
          <input
            autoFocus
            type="text"
            name="lastName"
            placeholder={"Nom*"}
            title={"Nom*"}
            pattern={"[a-zA-ZÀ-ž]"}
            required
          />
        </label>
      </div>
    </div>
  );
} // End function UserFormIdentity
