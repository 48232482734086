/**
 * @file Signature de l'utilisateur
 * @author Craspouille
 */
import React, { createRef, useContext, useRef } from "react";

/* Context */
import { ModifyContext } from "../../../pages/LetterPage";
import { getOkayClass } from "../../../utils";
import { NoSignatureSVG } from "../../svg/NoSignatureSVG";

/* SVG */

export function UserInfosSignature(props) {
  const { userDetails, handleClickPanelVisibility, blobSignature } =
    useContext(ModifyContext);

  const imgSignature = createRef(null);

  useRef(userDetails["signature"]);

  return (
    <div
      className="letter-signature"
      onClick={handleClickPanelVisibility}
      data-steppers={3}
    >
      <p className="user-signature" data-steppers={3} data-step={3}>
        <span
          className={
            userDetails["gender"] == null || userDetails["gender"] === "non"
              ? "hidden"
              : "okay"
          }
        >
          {userDetails["gender"] != null
            ? userDetails["gender"] === "male"
              ? "M."
              : "Mme"
            : ""}
        </span>
        <span className={getOkayClass(userDetails["firstName"])}>
          {userDetails["firstName"] == null
            ? "Prénom"
            : userDetails["firstName"]}
        </span>
        <span className={getOkayClass(userDetails["lastName"])}>
          {userDetails["lastName"] == null ? "Nom" : userDetails["lastName"]}
        </span>
      </p>
      <div className="img-signature" data-steppers={3} data-step={3}>
        {blobSignature !== null ? (
          <img
            className={"signature"}
            src={blobSignature}
            ref={imgSignature}
            alt="Signature de l'utilisateur"
          />
        ) : (
          <NoSignatureSVG />
        )}
      </div>
    </div>
  );
}
