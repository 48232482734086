import React, { useContext } from "react";
import { AppContext } from "../App";
import "../scss/footer.scss";

export const Footer = () => {
  const { appIsMobile } = useContext(AppContext);
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="code-phenix">
          Développé par&nbsp;
          <a href="https://codephenix.fr/" target="_blank" rel="noreferrer">
            CodePhenix
          </a>
          &nbsp; &&nbsp;
          <a
            href="https://studio.artefact3000.com/"
            target="_blank"
            rel="noreferrer"
          >
            Artefact
          </a>
        </div>
        {!appIsMobile && (
          <div className="legal-notices">
            <a href="/LegalNotices">Mentions légales</a>
          </div>
        )}
      </div>
    </footer>
  );
};
