/**
 * @file Questionnaire d'identité pour l'utilisateur
 * @author Craspouille
 */
import { useContext, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../../scss/side-panel-letter.scss";

/* Button */
import { VersatileButton } from "../../VersatileButton.jsx";

/* Form Components */
import { CompanyForm } from "./CompanyForm.jsx";
import { UserForm } from "./UserForm.jsx";
import { UserSignatureDateForm } from "./UserSignatureDateForm.jsx";

import axios from "axios";

/* Context */
import { AppContext } from "../../../App";
import { ModifyContext } from "../../../pages/LetterPage.jsx";
import { LetterUpdatedForm } from "../LetterContent/LetterUpdatedForm.jsx";

export function SidePanelForm() {
  const navigate = useNavigate();
  const { steppers, setSteppers, userDetails, companyDetails, blobSignature } =
    useContext(ModifyContext);

  const { modifiedLetter, captchaVerified } = useContext(AppContext);

  /* Liste des formulaires */

  const details = [
    null,
    <UserForm />,
    <CompanyForm />,
    <UserSignatureDateForm />,
    <LetterUpdatedForm />,
  ];
  const userCompleted = Object.keys(userDetails).every(
    (key) =>
      ["gender", "date"].includes(key) ||
      (userDetails[key] !== "" && userDetails[key] !== null)
  );
  const companyCompleted = Object.keys(companyDetails).every(
    (key) =>
      ["gender"].includes(key) ||
      (companyDetails[key] !== "" && companyDetails[key] !== null)
  );
  const dateCompleted = userDetails["date"] !== null;

  const formsCompleted = companyCompleted && userCompleted && dateCompleted;

  useEffect(() => {
    // On initial load, if no form is completed, we set the stepper to the first form that is not completed
    if (steppers === 0 && !formsCompleted) {
      if (!userCompleted) {
        setSteppers(1);
        return;
      }
      if (!companyCompleted) {
        setSteppers(2);
        return;
      }
      if (!dateCompleted) {
        setSteppers(3);
        return;
      }
    }
  });

  function downloadPdf() {
    if (!captchaVerified) {
      alert("Veuillez cocher la case 'Je ne suis pas un robot'");
      navigate("/Introduction");
      return;
    }
    const data = {
      user: userDetails,
      company: companyDetails,
      letter: modifiedLetter,
      signature: blobSignature,
    };
    if (userDetails["date"]) {
      data.user.date = new Date(userDetails["date"]).toLocaleString("fr-Fr", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    }

    if (userDetails["phone"]) {
      data.user.phone = userDetails["phone"]
        .split(/\s*([0-9]{0,2})\s*/)
        .join(" ");
    }
    const url = process.env.REACT_APP_BACKEND_BASE_URL + "/download-letter";

    axios
      .post(url, data, { responseType: "blob" })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        // var blob = new Blob([response.data]);
        var link = document.createElement("a");
        // link.href = window.URL.createObjectURL(blob);
        link.href = href;
        link.download = "lettre_de_motivation.pdf";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((error) => {
        console.error(
          "%c Error fetching PDF:",
          "background: red, color:red",
          error
        );
      });
  }
  return (
    <>
      {details[steppers]}
      <div className="control-widget finalize-and-modify">
        {formsCompleted && (
          <VersatileButton
            title={"Télécharger le PDF de votre lettre de motivation"}
            className="icon-download"
            content={"Télécharger"}
            onClickCallback={downloadPdf}
          />
        )}
      </div>
    </>
  );
}
